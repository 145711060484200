"use strict";

/**
 * GETTERS
 */
function drawCanvasArc() {
  var animate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var canvasBack = document.getElementById('canvas-background'),
      backgroundPage = canvasBack.getAttribute('data-background'),
      popupColor = canvasBack.getAttribute('data-popup');

  if (canvasBack.getContext) {
    var animateCanvasArc = function animateCanvasArc() {
      var src = '';
      dots[1].fill = true;

      if (percent >= 50) {
        dots[2].fill = true;

        if (percent >= 75) {
          dots[3].fill = true;
          percent = 87.5;
          score = {
            "x": dots[3].x,
            "y": dots[3].y,
            "txt": "75 %"
          };
          src = PICTURE.img['ending-canvas-3'];
        } else {
          percent = 62.5;
          score = {
            "x": dots[2].x,
            "y": dots[2].y,
            "txt": "50 %"
          };
          src = PICTURE.img['ending-canvas-2'];
        }
      } else {
        percent = 37.5;
        score = {
          "x": dots[1].x,
          "y": dots[1].y,
          "txt": "25 %"
        };
        src = PICTURE.img['ending-canvas-1'];
      }

      var scoreSpan = document.getElementsByClassName('score');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = scoreSpan[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var span = _step.value;
          span.innerHTML = score.txt;
        } //display img
        // let contextImage = document.getElementById('canvas-image')
        //     .getContext("2d");

      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"] != null) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var contextImage = context;
      var imgFlower = new Image();
      imgFlower.src = 'public/img/' + src;

      imgFlower.onload = function () {
        context.globalCompositeOperation = "source-over";
        contextImage.drawImage(imgFlower, 510 - Math.round(imgFlower.width / 2), 850 - imgFlower.height);
      }; //display circle


      interval = setInterval(animateArc, 20);
    };

    var animateArc = function animateArc() {
      if (x <= percent / 100 * y) {
        var _startAngle = start * Math.PI / (y / 2),
            _endAngle = (x + 1) * Math.PI / (y / 2);

        for (var _i2 = 0, _dots2 = dots; _i2 < _dots2.length; _i2++) {
          var _dot = _dots2[_i2];
          var A = Math.atan2(Math.round(_dot.y > 500 ? -(_dot.y - 500) : 500 - _dot.y), Math.round(_dot.x - 500));
          if (A <= 0) A = 2 * Math.PI + A;

          if (_endAngle >= A && _dot.fill) {
            _dot.fill = false;
            context.moveTo(_dot.x, _dot.y);
            context.beginPath();
            context.globalCompositeOperation = "source-over";
            context.lineWidth = 12;
            context.fillStyle = backgroundPage;
            context.strokeStyle = '#FFF';
            context.arc(_dot.x, _dot.y, radiusDot, startCloseCircle, endCloseCircle);
            context.fillStyle = '#FFF';
            context.stroke();
            context.fill();
          }
        }

        context.moveTo(Math.round(Math.cos(_startAngle) * radiusCircleEmpty), Math.round(Math.sin(_startAngle) * radiusCircleEmpty));
        context.beginPath();
        context.globalCompositeOperation = "destination-over";
        context.lineWidth = 10;
        context.strokeStyle = '#FFF';
        context.arc(abscissaCircle, ordinateCircle, radiusCircleEmpty, -_startAngle, -_endAngle, true);
        context.stroke();
        x++;
      } else {
        var widthRect = 200,
            heightRect = 80;

        if (score.y > 500) {
          score.y += heightRect;
          context.moveTo(score.x, score.y - 0.3 * heightRect);
          context.beginPath();
          context.fillStyle = popupColor;
          context.moveTo(score.x, score.y - 0.3 * heightRect);
          context.lineTo(score.x + 0.1 * widthRect, score.y);
          context.lineTo(score.x - 0.1 * widthRect, score.y);
          context.closePath();
          context.fill();
        } else {
          score.y += -2 * heightRect;
          context.moveTo(score.x, score.y + 1.3 * heightRect);
          context.beginPath();
          context.fillStyle = popupColor;
          context.moveTo(score.x, score.y + 1.3 * heightRect);
          context.lineTo(score.x + 0.1 * widthRect, score.y + heightRect);
          context.lineTo(score.x - 0.1 * widthRect, score.y + heightRect);
          context.closePath();
          context.fill();
        }

        roundRect(score.x - widthRect / 2, score.y, widthRect, heightRect, 20);
        context.globalCompositeOperation = "source-over";
        context.font = "bold 50px Arial";
        context.textAlign = "center";
        context.fillStyle = "#003F58";
        context.fillText(score.txt, score.x, score.y + 57);
        setTimeout(function () {
          document.getElementsByClassName('box-text-first')[0].classList.add('hidden');
          document.getElementsByClassName('box-text-second')[0].classList.remove('hidden');
        }, 1500);
        window.clearInterval(interval);
      }
    };

    var roundRect = function roundRect(x, y, w, h, radius) {
      var r = x + w;
      var b = y + h;
      context.beginPath();
      context.fillStyle = popupColor;
      context.moveTo(x + radius, y);
      context.lineTo(r - radius, y);
      context.quadraticCurveTo(r, y, r, y + radius);
      context.lineTo(r, y + h - radius);
      context.quadraticCurveTo(r, b, r - radius, b);
      context.lineTo(x + radius, b);
      context.quadraticCurveTo(x, b, x, b - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);
      context.fill();
    };

    var context = canvasBack.getContext("2d");
    var abscissaCircle = 500,
        ordinateCircle = 500,
        radiusCircleEmpty = 400,
        radiusCircleFill = 350,
        radiusDot = 18,
        initPointX = Math.cos(Math.PI / 4) * radiusCircleEmpty,
        initPointY = Math.sin(Math.PI / 4) * radiusCircleEmpty,
        startCloseCircle = 0,
        endCloseCircle = 2 * Math.PI;
    var dots = [{
      "x": initPointX + abscissaCircle,
      "y": ordinateCircle - initPointY,
      "fill": true
    }, {
      "x": abscissaCircle - initPointX,
      "y": ordinateCircle - initPointY,
      "fill": false
    }, {
      "x": abscissaCircle - initPointX,
      "y": initPointY + ordinateCircle,
      "fill": false
    }, {
      "x": initPointX + abscissaCircle,
      "y": initPointY + ordinateCircle,
      "fill": false
    }];

    if (animate) {
      animateCanvasArc();
    } else {
      console.log(percent); // clear the canvas

      context.clearRect(0, 0, canvasBack.width, canvasBack.height); //Circle FILL White Background

      context.beginPath();
      context.arc(abscissaCircle, ordinateCircle, radiusCircleFill, startCloseCircle, endCloseCircle);
      context.fillStyle = '#FFF';
      context.fill(); //Circle EMPTY DASHED White Stroke

      for (var _x = 20, _y = 160; _x < _y / 8 * 7; _x++) {
        if (_x % 2 === 0) {
          var startAngle = _x * Math.PI / (_y / 2),
              endAngle = (_x + 1) * Math.PI / (_y / 2),
              startX = Math.round(Math.cos(startAngle) * radiusCircleEmpty),
              startY = Math.round(Math.sin(startAngle) * radiusCircleEmpty);
          context.moveTo(startX, startY);
          context.beginPath();
          context.lineWidth = 7;
          context.strokeStyle = '#FFF';
          context.arc(abscissaCircle, ordinateCircle, radiusCircleEmpty, startAngle, endAngle);
          context.stroke();
        }
      } //Dots


      for (var _i = 0, _dots = dots; _i < _dots.length; _i++) {
        var dot = _dots[_i];
        context.moveTo(dot.x, dot.y);
        context.beginPath();
        context.lineWidth = 5;
        context.fillStyle = backgroundPage;
        context.strokeStyle = '#FFF';
        context.arc(dot.x, dot.y, radiusDot, startCloseCircle, endCloseCircle);

        if (dot.fill) {
          context.fillStyle = '#FFF';
        }

        context.fill();
        context.stroke();
      }
    }

    var y = 200,
        x = y / 8,
        start = x,
        end = false,
        score,
        interval;
  }
}