"use strict";

/**
 * AJAX & URL
 */
function request(file) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          reject(xhr);
        }
      }
    };

    xhr.open('GET', file, true);
    xhr.send(null);
  });
}

function $GET(param) {
  var vars = {};
  window.location.href.replace(location.hash, '').replace(/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
  function (m, key, value) {
    // callback
    vars[key] = value !== undefined ? value : '';
  });

  if (param) {
    return vars[param] ? vars[param] : null;
  }

  return vars;
}