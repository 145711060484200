"use strict";

var PUBPATH = "public/";
var config, quizzDatas;
var $_GET = $GET();
var lang = $_GET['lang'] !== undefined ? $_GET['lang'] : "fr";
var intro, part, question, section, carousel;
var paging, circleFull, circleEmpty;
var id_question = 0,
    id_subquestion = 0,
    id_name = -1,
    id_answer = 0;
var lastQuestion;
var ANSWERS = {};
var savedAnswers;
var COLOR;
var PICTURE;
var percent = 20;
var thematiques = {};
var idT = -1;
getConf().then(function (datas) {
  console.log(lang = $_GET['lang'] !== undefined ? $_GET['lang'] : "fr");
  COLOR = config.color;
  PICTURE = config.pictureUsage;
  getQuestions().then(function (datas) {
    createIntro();
  })["catch"](catchError);
})["catch"](catchError);

function catchError(e) {
  console.error('Erreur :', e);
}

function getConf() {
  return request(PUBPATH + "config/config.json").then(function (response) {
    config = JSON.parse(response);
  })["catch"](catchError);
}

function getQuestions() {
  return request(PUBPATH + "config/question.json").then(function (response) {
    quizzDatas = JSON.parse(response);
  })["catch"](catchError);
}
/**
 * CREATE QUIZZ
 */


function getTitle(part) {
  return part.lang[lang].title;
}

function getSubtitle(currentNumberPart, NumberOfParts) {
  return config.lang[lang].subtitle.replace('$1', currentNumberPart).replace('$2', NumberOfParts);
}

function getPicture(part, question) {
  var picture = "intro/fleur-accueil.png";

  if (part.hasOwnProperty('picture') && question.hasOwnProperty('picture')) {
    picture = question.picture === "" ? part.picture : question.picture;

    if (question.picture === "") {
      if (part.picture === "") picture = "test.jpg";else picture = part.picture;
    } else picture = question.picture;
  }

  return '<img class="img-fluid" width="960" height="540" alt="illustration questions" src="' + PUBPATH + 'img/' + picture + '">';
}

function getPictureByName(usage) {
  var alt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var picture = "intro/fleur-accueil.png";

  if (PICTURE.img.hasOwnProperty(usage) && alt !== undefined) {
    picture = PICTURE.img[usage];
    return '<img class="img-fluid" width="960" height="540" ' + 'alt="' + alt + '" src="' + PUBPATH + 'img/' + picture + '">';
  }

  if (PICTURE.svg.icon.hasOwnProperty(usage)) {
    picture = "svg-symbols.svg#" + PICTURE.svg.icon[usage];
    return '<svg>' + '<use xlink:href="' + PUBPATH + 'img/' + picture + '"></use>' + '</svg>';
  }

  if (PICTURE.svg.color.hasOwnProperty(usage)) {
    picture = PICTURE.svg.color[usage];
    return '<img class="img-fluid"' + 'alt="" src="' + PUBPATH + 'img/' + picture + '">';
  }

  return '<img class="img-fluid" width="960" height="540" ' + 'alt="' + alt + '" src="' + PUBPATH + 'img/' + picture + '">';
}

function getPaging(part, currentNumberQuestion) {
  paging = "";

  for (var k = 0; k < part.questions.length; k++) {
    paging = paging.concat(k <= currentNumberQuestion ? circleFull : circleEmpty);
  }

  return paging;
}

function getQuestion(question) {
  return question.lang[lang].question;
}

function validAnswer(question, subquestion, l) {
  return question.lang[lang].answer[l].hasOwnProperty("sub") && parseInt(question.lang[lang].answer[l].sub) === subquestion || !question.lang[lang].answer[l].hasOwnProperty("sub");
}

function getAnswerQcmRadio(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var inputs = '';

  for (var l = 0; l < question.lang[lang].answer.length; l++) {
    if (validAnswer(question, subquestion, l)) {
      inputs = inputs.concat('<div class="container-answer" data-idT="' + idT + '" ' + 'style="' + getStyle(question.lang[lang].answer[l], question) + '">' + '<input id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + ' input-qcm-radio" ' + 'name="question' + id_question + 'name' + id_name + '" ' + 'type="radio" ' + 'value="' + question.lang[lang].answer[l].value + '" ' + 'data-advice="' + question.lang[lang].answer[l].advice + '" ' + 'data-thematique="' + getTitle(part) + '">' + '<label ' + 'for="question' + id_question + 'answer' + id_answer + '" ' + 'data-background="' + getColor("button") + '" ' + 'data-shadow="' + getColor("border") + '" ' + 'style="background-color:' + getColor("default-button") + '">' + '<span>' + circleEmpty + '</span>' + question.lang[lang].answer[l].content + '</label>' + '</div>');
      id_answer++;
    }
  }

  return inputs;
}

function getAnswerQcmCheckbox(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var inputs = '';

  for (var l = 0; l < question.lang[lang].answer.length; l++) {
    if (validAnswer(question, subquestion, l)) {
      inputs = inputs.concat('<div class="container-answer" data-idT="' + idT + '" ' + 'style="' + getStyle(question.lang[lang].answer[l], question) + '">' + '<input id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + ' input-qcm-checkbox" ' + 'name="question' + id_question + 'name' + id_name + 'answer' + l + '" ' + 'type="checkbox" ' + 'value="' + question.lang[lang].answer[l].value + '" ' + 'data-advice="' + question.lang[lang].answer[l].advice + '" ' + 'data-answer="' + question.lang[lang].answer[l].content + '" ' + 'data-thematique="' + getTitle(part) + '">' + '<label ' + 'for="question' + id_question + 'answer' + id_answer + '" ' + 'data-background="' + getColor("button") + '" ' + 'data-shadow="' + getColor("border") + '" ' + 'style="background-color:' + getColor("default-button") + '">' + '<span>' + circleEmpty + '</span>' + question.lang[lang].answer[l].content + '</label>' + '</div>');
      id_answer++;
    }
  }

  return inputs;
}

function getAnswerRange(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var datalist, mark, nbMark;
  var label = '<div class="container-answer" data-idT="' + idT + '">';
  var inputs = '<input ' + 'id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + '" ' + 'name="question' + id_question + 'name' + id_name + '" ' + 'type="range" ' + 'style="color: ' + getColor('border') + '"';

  for (var l = 0; l < question.lang[lang].answer.length; l++) {
    if (validAnswer(question, subquestion, l)) {
      var answer = question.lang[lang].answer[l];

      if (answer.list === "no") {
        inputs = label.concat('<label for="question' + id_question + 'answer' + id_answer + '" ' + 'class="input-range-label" ' + 'style="' + getStyle(question.lang[lang].answer[l], question) + 'background: ' + getColor('button') + ';' + ' color: ' + getColor('button') + '">' + '<span style="color: ' + getColor('range') + '"></span>' + '</label>' + inputs);
        inputs = inputs.concat('data-range-type="1" value="' + answer.min + '" ' + 'min="' + answer.min + '" ' + 'max="' + answer.max + '" step="' + answer.step + '" ' + 'data-advice="' + question.lang[lang].answer[l].advice + '" >');
        inputs = inputs.concat('');
        mark = '<div class="box-mark">';
        nbMark = (answer.max - answer.min) / answer.step % 2 === 1 ? (answer.max - answer.min) / answer.step / 2 + 0.5 : (answer.max - answer.min) / answer.step;

        for (var n = 0; n <= nbMark; n++) {
          mark = mark.concat('<div>|</div>');
        }

        mark = mark.concat('</div>');
        inputs = inputs.concat(mark + '<div class="box-minmax" ' + 'style="color: ' + getColor('text') + '">' + '<span>' + answer.min + '</span>' + '<span>' + answer.max + '</span>' + '</div>');
      } else {
        label = label.concat('<div class="box-minmax-value" ' + 'style="color: ' + getColor('text') + '">');
        inputs = inputs.concat('data-range-type="2" value="0" min="0" ' + 'max="' + (answer.options.length - 1) + '" ' + 'step="1" list="range' + subquestion + '">' + '</div>');
        datalist = '<datalist id="range' + subquestion + '">';

        for (var m = 0; m < answer.options.length; m++) {
          if (m === 0) {
            inputs = label.concat('<span>' + answer.options[m] + '</span>' + '<div><label for="question' + id_question + 'answer' + id_answer + '" ' + 'class="input-range-label" ' + 'style="background: ' + getColor('button') + ';' + ' color: ' + getColor('button') + '">' + '<span style="color: ' + getColor('range') + '"></span>' + '</label>' + '<div class="range-track">' + inputs + '</div>');
          }

          datalist = datalist.concat('<option data-advice="' + question.lang[lang].answer[l].advice + '" value="' + m + '" label="' + answer.options[m] + '">');

          if (m === answer.options.length - 1) {
            inputs = inputs.concat('<span>' + answer.options[m] + '</span></div>');
          }
        }

        inputs = inputs.concat(datalist + '</datalist>');
      }

      id_answer++;
    }
  }

  inputs = inputs.concat('</div>');
  return inputs;
}

function getAnswerField(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  for (var l = 0; l < question.lang[lang].answer.length; l++) {
    if (validAnswer(question, subquestion, l)) {
      var answer = question.lang[lang].answer[l];
      var required = answer.required === 1 ? '* ' : '';
      var inputs = answer.hasOwnProperty('type') ? '<div class="container-answer" data-idT="' + idT + '" ' + 'style="' + answer.style + '">' + '<textarea  id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + '" ' + 'name="question' + id_question + 'name' + id_name + '" ' + 'style="' + getStyle(question.lang[lang].answer[l], question) + 'background: ' + getColor('default-button') + ';' + 'border: solid .2rem ' + getColor('border') + ';' + 'color: ' + getColor('text') + '; ' + answer.style + '" ' + 'data-color-border="' + getColor('border') + '" ' + 'placeholder="' + required + answer.content + '">' + '</textarea>' + '</div>' : '<div class="container-answer" data-idT="' + idT + '" ' + 'style="' + answer.style + '">' + '<input  id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + '" ' + 'name="question' + id_question + 'name' + id_name + '" ' + 'style="background: ' + getColor('default-button') + ';' + 'border: solid .2rem ' + getColor('border') + ';' + 'color: ' + getColor('text') + '; ' + answer.style + '" ' + 'type="text" ' + 'data-color-border="' + getColor('border') + '" ' + 'placeholder="' + required + answer.content + '">' + '</div>';
      id_answer++;
      return inputs;
    }
  }

  return '';
}

function getAnswerSelect(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  for (var l = 0; l < question.lang[lang].answer.length; l++) {
    if (validAnswer(question, subquestion, l)) {
      var answer = question.lang[lang].answer[l];
      var required = answer.required === 1 ? '* ' : '';
      var inputs = '<div class="container-answer" data-idT="' + idT + '" ' + 'style="' + getStyle(question.lang[lang].answer[l], question) + '">' + '<div class="select-box" ' + 'style="background: ' + getColor('default-button') + '; ' + 'border: solid .2rem ' + getColor('border') + ';" >' + '<select  id="question' + id_question + 'answer' + id_answer + '" ' + 'class="question' + id_question + 'subquestion' + id_subquestion + '" ' + 'data-color-border="' + getColor('border') + '" ' + 'name="question' + id_question + 'name' + id_name + '" ' + 'style="background: ' + getColor('default-button') + ';' + 'color: ' + getColor('text') + '; ' + answer.style + '">' + '<option data-default selected disabled value="">' + required + answer.content + '</option>';
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = answer.answer[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var option = _step.value;
          inputs = inputs.concat('<option value="' + option + '">' + option + '</option>');
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator["return"] != null) {
            _iterator["return"]();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      inputs = inputs.concat('</select>' + getPictureByName('chevron-down') + '</div></div>');
      id_answer++;
      return inputs;
    }
  }

  return '';
}

function getAnswerMultiple(question) {
  var answer = '';

  for (var x = 0; x < question.lang[lang].subquestions.length; x++) {
    answer = answer.concat("<fieldset " + "data-subquestion-type='" + question.lang[lang].subquestions[x].type + "' " + "data-check-response='0' >" + "<legend>" + question.lang[lang].subquestions[x].subquestion) + "</legend>";
    answer = answer.concat(getAnswer(question, x));
    answer = answer.concat("</fieldset>");
    id_subquestion++;
  }

  return answer;
}

function getAnswer(question) {
  var subquestion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  id_name++;
  var type = subquestion === null ? question.type : question.lang[lang].subquestions[subquestion].type;

  switch (type) {
    case "qcm-radio":
      return getAnswerQcmRadio(question, subquestion);

    case "qcm-checkbox":
      return getAnswerQcmCheckbox(question, subquestion);

    case "field":
      return getAnswerField(question, subquestion);

    case "range":
      return getAnswerRange(question, subquestion);

    case "select":
      return getAnswerSelect(question, subquestion);

    case "multiple":
      id_name--;
      return getAnswerMultiple(question);

    default:
      console.error("Type de question invalide : " + question.type + " (" + question.lang[lang].question + ")");
  }
}

function getColor(usage) {
  if (COLOR.usage.hasOwnProperty(usage)) {
    return COLOR.code[COLOR.usage[usage]];
  } else {
    if (question.hasOwnProperty(usage + "-color")) {
      return COLOR.code[question[usage + "-color"]];
    } else {
      return COLOR.code[part[usage + "-color"]];
    }
  }
}

function getStyle(answer, question) {
  if (answer.hasOwnProperty("style")) {
    return answer.style;
  } else if (question.lang[lang].hasOwnProperty("style")) {
    return question.lang[lang].style;
  } else {
    return "";
  }
}

function getText(usage) {
  if (part.lang[lang].hasOwnProperty(usage)) {
    return part.lang[lang][usage];
  }
}

function createHeader() {
  return '<div class="slide-header">' + '<div class="logo">' + '<a href="' + config.url.urlLogo + '">' + getPictureByName('logo') + '</a>' + '</div>' + '</div>';
}

function createFooter() {
  return '<div class="slide-footer">' + '<p>' + 'Expérience proposée par ' + '<a href="https://www.semafor.fr/">Semafor</a> ' + 'dans le cadre de l\'offre ' + '<a href="' + config.url.urlLogo + '">YapluK</a>' + '</p>' + '</div>';
}

function createIntro() {
  circleFull = getPictureByName('circle-full');
  circleEmpty = getPictureByName('circle-empty');
  intro = part = quizzDatas.intro[0];
  id_subquestion = 0;
  question = intro.questions[0];
  section = document.createElement("SECTION");
  section.id = "question" + id_question;
  section.className = "carousel__photo";
  section.insertAdjacentHTML('afterbegin', '<article class="intro" data-question-type="' + question.type + '" data-check-response="0">' + '<div class="left-img">' + getPictureByName('intro-mouche') + '</div>' + '<div class="container" style="color: ' + getColor('text') + '">' + '<div class="box-content" style="background: ' + getColor('back') + '">' + '<div class="box-title" style="color: ' + getColor('title') + '">' + '<h3 class="title">' + intro.lang[lang].title + '</h3>' + '<h4 class="subtitle">' + intro.lang[lang].subtitle + '</h4>' + '</div>' + '<div class="box-form" style="background: ' + getColor('back') + '">' + getAnswer(question) + "<div class='warningFieldEmpty hide'>Merci de remplir tout les champs.</div>" + '</div>' + '</div>' + "<button class='nextSlide carousel__button--next' " + "style='color: " + getColor("text") + "; " + "background: " + getColor("button") + ";' >" + "Suivant</button>" + '</div>' + '<div class="right-img">' + getPictureByName('intro-abeille') + '</div>' + '</article>');
  document.getElementsByClassName("carousel")[0].appendChild(section);
  id_question++;
  createQuizz();
}

function createQuizz() {
  for (var i = 0; i < quizzDatas.parts.length; i++) {
    part = quizzDatas.parts[i];
    idT = i;
    thematiques[i] = {
      backColor: getColor("back"),
      buttonColor: getColor("button"),
      title: getTitle(part)
    };

    for (var j = 0; j < part.questions.length; j++) {
      id_subquestion = 0;
      question = part.questions[j];
      section = document.createElement("SECTION");
      section.id = "question" + id_question;
      section.className = "carousel__photo";
      section.insertAdjacentHTML('afterbegin', "<aside class='container-part'>" + "<div class='container-header'>" + "<div class='container-title' style='color: " + getColor("title") + "'>" + getTitle(part) + "</div>" + "<div class='container-subtitle' style='color: " + getColor("subtitle") + "'>" + getSubtitle(i + 1, quizzDatas.parts.length) + "</div>" + "</div>" + '<div class="container-mobile-header">' + '<div class="container-query-number" style="color: ' + getColor("subtitle") + '">' + 'Question ' + id_question + '</div>' + '<div class="container-query" style="color: ' + getColor("title") + '">' + getQuestion(question) + '</div>' + '</div>' + "<div class='container-content'>" + "<div class='container-picture'>" + getPicture(part, question) + "</div>" + "</div>" + "</aside>" + "<article class='container-question' data-question-type='" + question.type + "' data-check-response='0' " + "style='background-color:" + getColor("back") + "; " + "color:" + getColor("text") + ";'>" + "<div class='container-header'>" + "<div class='container-paging' style='color: " + getColor("paging") + "'>" + getPaging(part, j) + "</div>" + "<div class='container-query' style='color: " + getColor("question") + "'>" + getQuestion(question) + "</div>" + "</div>" + "<div class='container-content'>" + "<div class='container-form'>" + getAnswer(question) + "</div>" + "</div>" + "<div class='warningNoAnswer hide'>Merci de séléctionner au moins une option.</div>" + "<div class='container-footer'>" + "<div class='container-validate'>" + "<button class='lastSlide carousel__button--prev'>" + getPictureByName('arrow-left') + "Question Précédente</button>" + "<button class='nextSlide carousel__button--next' " + "data-background='" + getColor("button") + "'>" + "Suivant</button>" + "</div>" + "</div>" + "</article>");
      document.getElementsByClassName("carousel")[0].appendChild(section);
      id_question++;
    }
  }

  idT = -1;
  lastQuestion = section.id;
  createEnding();
}

function createEnding() {
  question = quizzDatas.ending[0].questions[0];
  part = quizzDatas.ending[0];
  createPercentConclusion();
  createAdviceConclusion();
  createPersonnalDataCaptureForm();
  createThanksSlide();
  attachEvents();
}

function createPercentConclusion() {
  section = document.createElement("SECTION");
  section.id = "question" + id_question;
  section.className = "carousel__photo";
  section.style.backgroundColor = getColor('back-percent');
  document.getElementsByClassName("carousel")[0].appendChild(section);
  id_question++;
  section.insertAdjacentHTML('afterbegin', '<article class="container-percent" ' + 'style="color: ' + getColor('title') + '">' + '<div class="box-anim">' + '<canvas id="canvas-background" width="1000" height="1000" ' + 'data-background="' + getColor("back-percent") + '" ' + 'data-popup="' + getColor("popup") + '">' + '</canvas>' + '</div>' + '<div class="container-percent-txt">' + '<div class="box-text-first">' + '<p>' + getText('catchPhrase') + '</p>' + '<span class="score"></span>' + '</div>' + '<div class="box-text-second hidden">' + '<div class="container-text">' + '<p class="catchPhrase">' + getText('catchPhrase') + '<span class="score"></span>' + '</p>' + '<p class="subCatchPhrase">' + getText('subCatchPhrase') + '</p>' + '</div>' + '<button class="nextSlide carousel__button--next" ' + 'data-background="' + getColor("button") + '">' + 'Suivant</button>' + '</div>' + '</div>' + '</article>');
}

function createAdviceConclusion() {
  section = document.createElement("SECTION");
  section.id = "question" + id_question;
  section.className = "advice-conclusion carousel__photo";
  section.style.flexFlow = "column nowrap";
  section.style.overflowY = "auto";
  section.insertAdjacentHTML('afterbegin', createHeader() + '<article id="advice-conclusion" class="advice-conclusion" ' + 'style="color: ' + getColor('title') + '; ' + 'background-image: url(' + PUBPATH + 'img/' + PICTURE.svg.color['ending-background-right'] + ');">' + '<div class="box-general-advice">' + '<div class="left" style="background-image: url(' + PUBPATH + 'img/' + PICTURE.svg.color['ending-background-left'] + ');"></div>' + '<div class="container-text">' + '<div class="title">' + getText('title-advice') + '</div>' + '<p class="text">' + getText('text-advice') + '</p>' + '<p class="text-bold">' + getText('text-advice2') + '</p>' + '</div>' + getPictureByName('ending-advice-conclusion', 'Illustration conseil') + '<div class="right"></div>' + '</div>' + '<div class="container-advice">' + '<button class="lastAdvice" ' + 'style="background:' + getColor('general-button') + '; ' + 'color: ' + getColor('title') + '">' + getPictureByName('arrow-left') + '</button>' + '<div id="container-slider" class="container-slider">' + '</div>' + '<button class="nextAdvice" ' + 'style="background:' + getColor('general-button') + '; ' + 'color: ' + getColor('title') + '">' + getPictureByName('arrow-right') + '</button>' + '</div>' + '<button class="nextSlide carousel__button--next" ' + 'style="color: ' + getColor('title') + '">' + 'Recevoir mes résultats</button>' + '</article>');
  document.getElementsByClassName("carousel")[0].appendChild(section);
  id_question++;
}

function createPersonnalDataCaptureForm() {
  section = document.createElement("SECTION");
  section.id = "question" + id_question;
  section.className = "personnalDataCaptureForm carousel__photo";
  section.style.flexFlow = "column nowrap";
  section.style.overflowY = "auto";
  section.insertAdjacentHTML('afterbegin', createHeader() + '<article id="personnalDataCaptureForm" class="contactForm" ' + 'data-question-type="' + question.type + '" data-check-response="0" ' + 'style="color: ' + getColor('title') + '; ' + 'background-color: ' + getColor('back-form') + '">' + '<div class="left">' + '<button  class="previousSlide carousel__button--prev" ' + 'style="background: ' + getColor("button") + '; ' + 'color: ' + getColor('title') + '">' + getPictureByName('arrow-left') + '</button>' + '</div>' + '<aside class="container-frame">' + getPictureByName("ending-form-contact", "Illustration formulaire de contact") + '</aside>' + '<article class="container">' + '<div class="box-title">' + '<h2 class="title">' + getText('title-form') + '</h2>' + '<div class="box-undertitle">' + '<p class="text">' + getText('undertitle-form') + '</p>' + '<div class="frame">' + getPictureByName("ending-form-contact") + '</div>' + '</div>' + '</div>' + '<div class="box-form" style="' + 'background-color: ' + getColor('back-form') + '">' + getAnswer(question) + '<label class="cbxRgpdContainer"><input type="checkbox" id="cbxRgpd"><span id="checkmark" class="checkmark"></span>J\'accepte que mes données soient transmises à Semafor.</label>' + '</div class="box-button">' + '<button class="nextSlide sendContactDatas carousel__button--next" ' + 'style="background:' + getColor("button") + '">' + 'Valider</button>' + '</article>' + '<div class="right">' + '<button id="restart" class="nextSlide" ' + 'style="background: ' + getColor("button") + '; ' + 'color: ' + getColor('title') + '">' + getPictureByName('retry') + '</button>' + '</div>' + '</article>' + createFooter());
  document.getElementsByClassName("carousel")[0].appendChild(section);
  id_question++;
}

function createThanksSlide() {
  section = document.createElement("SECTION");
  section.id = "question" + id_question;
  section.className = "thanksSlide carousel__photo";
  section.style.flexFlow = "column nowrap";
  section.style.overflowY = "auto";
  section.insertAdjacentHTML('afterbegin', createHeader() + '<article class="container-thanks" ' + 'style="color: ' + getColor('title') + '">' + '<div class="containerFleurTexte">' + '<div class="imgfleur">' + getPictureByName("ending-form-contact", "Illustration thanks slide") + '</div>' + '<div class="box-text">' + '<p>' + getText('text-thanksSlide') + '</p>' + '<p>' + getText('text-thanksSlide2') + '</p>' + '</div>' + '</div>' + '<div id="btnsThanksSlide">' + '<button  class="previousSlide lastGetAdvicesSlide" ' + 'data-background="' + getColor("general-button") + '">' + 'Accéder à ma synthèse</button>' + '<button id="restart" class="nextSlide" ' + 'data-background="' + getColor("general-button") + '">' + 'Recommencer</button>' + '</div>' + '</article>' + createFooter());
  document.getElementsByClassName("carousel")[0].appendChild(section);
  id_question++;
}