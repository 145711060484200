"use strict";

/**
 * EVENTS
 */
function attachEvents() {
  load_carousel();
  beginQuizz();
  saveValuesInput();
  switchPage();
  animButton();
  animButtonAutres();
  drawCanvasArc();
  restartQuizz();
}

function beginQuizz() {
  animButtonBegin(); // document.getElementById('beginQuizz').addEventListener("click", () => {
  //     // document.getElementById("home-box").className = "hidden";
  //     // document.getElementById('question0').classList.remove("hidden");
  // });
}

function restartQuizz() {
  var buttonRestart = document.querySelector('#restart');
  buttonRestart.addEventListener("click", function () {
    document.location.reload();
  });
}

function switchPage() {
  var nextButtons, lastId;
  var articles = document.querySelectorAll('article[data-question-type]');

  var _loop = function _loop(a) {
    nextButtons = articles[a].querySelector('.nextSlide');

    if (nextButtons) {
      nextButtons.addEventListener("click", function () {
        if (articles[a].getAttribute('data-check-response') == 1) {
          if (lastQuestion === 'question' + a) {
            computeGoodPercent();
            submitFormDatas();
            drawCanvasArc(true);
            displayAdvice();
            nextAdvice();
            lastAdvice();
          }

          console.log(ANSWERS);
        } else {
          if (articles[a].getAttribute('class') == "intro") {
            var inputs = articles[a].querySelectorAll('[data-subquestion-type]');

            for (var i = 0; i < inputs.length; i++) {
              changeBorderField(inputs[i]);
              articles[a].getElementsByClassName("warningFieldEmpty")[0].classList.remove("hide");
              articles[a].getElementsByClassName("warningFieldEmpty")[0].classList.add("show");
            }
          } else {
            articles[a].getElementsByClassName("warningNoAnswer")[0].classList.remove("hide");
            articles[a].getElementsByClassName("warningNoAnswer")[0].classList.add("show");
          }
        }
      });
    }

    lastId = a;
  };

  for (var a = 0; a < articles.length - 1; a++) {
    _loop(a);
  }

  document.getElementById('question' + (lastId + 2)).querySelector('.nextSlide').addEventListener("click", function () {
    if (document.getElementsByClassName("advice-conclusion")[0].getAttribute("restart") === null) {
      savedAnswers = ANSWERS;
      ANSWERS = {};
    } else {
      document.location.reload();
    }
  });
  nextButtons = articles[articles.length - 1].querySelector('.nextSlide');
  nextButtons.addEventListener("click", function () {
    var cbxRgpd = document.getElementById("cbxRgpd");

    if (articles[articles.length - 1].getAttribute('data-check-response') == 1 && cbxRgpd.checked) {
      submitContactDatas();
      console.log(ANSWERS);
    } else {
      var inputs = articles[articles.length - 1].querySelectorAll('[data-subquestion-type]');

      for (var i = 0; i < inputs.length; i++) {
        changeBorderField(inputs[i]);
      }

      if (!cbxRgpd.checked) {
        document.getElementById("checkmark").style.border = ".2rem solid red";
      }
    }
  });
  document.getElementById("personnalDataCaptureForm").addEventListener("change", function () {
    var cbxRgpd = document.getElementById("cbxRgpd");

    if (cbxRgpd.checked) {
      document.getElementById("checkmark").style.border = ".2rem solid " + getColor("border-checkbox");
    }
  });
  /*
  let returnToAdviceButton = document.getElementsByClassName('getAdvicesSlide');
  returnToAdviceButton[0].addEventListener("click", () => {
      document.getElementsByClassName("personnalDataCaptureForm")[0].classList.remove("active");
      document.getElementsByClassName("advice-conclusion")[0].classList.add("active");
  });
  */

  var lastReturnToAdviceButton = document.getElementsByClassName('lastGetAdvicesSlide');
  lastReturnToAdviceButton[0].addEventListener("click", function () {
    document.getElementsByClassName("thanksSlide")[0].classList.remove("active");
    document.getElementsByClassName("advice-conclusion")[0].classList.add("active");
    document.getElementsByClassName("advice-conclusion")[0].setAttribute("restart", 1);
    document.getElementsByClassName("advice-conclusion")[0].querySelector(".nextSlide").innerHTML = "Recommencer";
  });
}

function saveValuesInput() {
  var allInputs = document.querySelectorAll('[data-question-type]');

  for (var a = 0; a < allInputs.length; a++) {
    getResultQuestion(allInputs[a]);
    checkResponse(allInputs[a]);
  }
}

function animButton() {
  var article = document.querySelectorAll('.container-question');

  for (var d = 0; d < article.length; d++) {
    animButtonOnSelect(article[d]);
    animButtonOnHover(article[d]);
  }
}

function animButtonBegin() {
  var buttonBegin = document.querySelector('#beginQuizz');
  buttonBegin.addEventListener("mouseover", function () {
    this.style.boxShadow = getColor("hover-button") + " 0 1rem";
  });
  buttonBegin.addEventListener("mouseout", function () {
    this.style.boxShadow = "none";
  });
}

function animButtonAutres() {
  var buttons = [];
  buttons.push(document.querySelectorAll('.lastAdvice'));
  buttons.push(document.querySelectorAll('.nextAdvice'));
  buttons.push(document.querySelectorAll('.previousSlide'));
  var buttonsSuivants = document.querySelectorAll('.nextSlide');

  for (var i = 0; i < buttonsSuivants.length; i++) {
    buttonsSuivants[i].addEventListener("mouseover", function () {
      this.style.boxShadow = getColor("hover-button") + " 0 1rem";
    });
    buttonsSuivants[i].addEventListener("mouseout", function () {
      this.style.boxShadow = "none";
    });
  }

  buttons.forEach(function (item, index, array) {
    item[0].addEventListener("mouseover", function () {
      this.style.boxShadow = getColor("hover-button") + " 0 1rem";
    });
    item[0].addEventListener("mouseout", function () {
      this.style.boxShadow = "none";
    });
  });
}

function animButtonOnSelect(article) {
  var multiple = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type, form, containers, input, label, text, color;

  if (multiple) {
    type = article.getAttribute("data-subquestion-type");
    form = article;
  } else {
    type = article.getAttribute("data-question-type");
    form = article.querySelector('.container-form');
  }

  switch (type) {
    case 'qcm-radio':
      form.addEventListener("click", function () {
        if (form.querySelectorAll('input:checked').length >= 1) {
          containers = form.querySelectorAll('.container-answer');

          for (var e = 0; e < containers.length; e++) {
            input = containers[e].querySelector('input');
            label = containers[e].querySelector('label');

            if (input.checked) {
              label.style.color = getColor('text');
              label.style.background = label.getAttribute("data-background");
              label.style.boxShadow = label.getAttribute("data-shadow") + ' 0 1rem';
              text = label.querySelector('span');

              if (text.innerHTML !== circleFull) {
                text.innerHTML = circleFull;
              }
            } else {
              label.style.color = getColor('deactiv-button');
              label.style.background = getColor("default-button");
              label.style.boxShadow = '';
              text = label.querySelector('span');

              if (text.innerHTML !== circleEmpty) {
                text.innerHTML = circleEmpty;
              }
            }
          }
        } else {
          label = form.querySelectorAll('label');

          for (var f = 0; f < label.length; f++) {
            label[f].style.background = getColor("default-button");
            label[f].style.color = getColor('text');
            label[f].style.boxShadow = '';
            text = label[f].querySelector('span');
            if (text.innerHTML !== circleEmpty) text.innerHTML = circleEmpty;
          }
        }
      });
      break;

    case 'qcm-checkbox':
      form.addEventListener("input", function (event) {
        containers = form.querySelectorAll('.container-answer');
        var inputSelected = event.target;
        var isInputSelected = inputSelected.hasAttribute('name');

        if (form.querySelectorAll('input:checked').length >= 1 && isInputSelected) {
          var labelSelected = inputSelected.parentElement.querySelector('label');

          if (inputSelected.checked && labelSelected.innerText === 'Aucune') {
            containers.forEach(function (item, index) {
              input = item.querySelector('input');
              label = item.querySelector('label');

              if (label.innerText !== "Aucune") {
                input.checked = false;
                label.style.background = getColor("default-button");
                label.style.boxShadow = '';
                text = label.querySelector('span');

                if (text.innerHTML !== circleEmpty) {
                  text.innerHTML = circleEmpty;
                }
              } else {
                label.style.background = label.getAttribute("data-background");
                label.style.boxShadow = label.getAttribute("data-shadow") + ' 0 1rem';
                text = label.querySelector('span');

                if (text.innerHTML !== circleFull) {
                  text.innerHTML = circleFull;
                }
              }
            });
          }

          if (inputSelected.checked && labelSelected.innerText !== 'Aucune') {
            for (var e = 0; e < containers.length; e++) {
              input = containers[e].querySelector('input');
              label = containers[e].querySelector('label');

              if (label.innerText === 'Aucune') {
                input.checked = false;
                label.style.background = getColor("default-button");
                label.style.boxShadow = '';
                text = label.querySelector('span');

                if (text.innerHTML !== circleEmpty) {
                  text.innerHTML = circleEmpty;
                }
              } else if (input.checked && label.innerText !== 'Aucune') {
                label.style.background = label.getAttribute("data-background");
                label.style.boxShadow = label.getAttribute("data-shadow") + ' 0 1rem';
                text = label.querySelector('span');

                if (text.innerHTML !== circleFull) {
                  text.innerHTML = circleFull;
                }
              } else {
                label.style.background = getColor("default-button");
                label.style.boxShadow = '';
                text = label.querySelector('span');

                if (text.innerHTML !== circleEmpty) {
                  text.innerHTML = circleEmpty;
                }
              }
            }
          }

          if (!inputSelected.checked) {
            var _labelSelected = inputSelected.parentElement.querySelector('label');

            _labelSelected.style.background = getColor("default-button");
            _labelSelected.style.boxShadow = '';
            text = _labelSelected.querySelector('span');

            if (text.innerHTML !== circleEmpty) {
              text.innerHTML = circleEmpty;
            }
          }
        } else {
          label = form.querySelectorAll('label');

          for (var f = 0; f < label.length; f++) {
            label[f].style.background = getColor("default-button");
            label[f].style.color = getColor('text');
            label[f].style.boxShadow = '';
            text = label[f].querySelector('span');
            if (text.innerHTML !== circleEmpty) text.innerHTML = circleEmpty;
          }
        }
      });
      break;

    case 'range':
      var rangeSlider = form.querySelector("input");
      var rangeBullet = form.querySelector("label");
      if (parseInt(rangeSlider.getAttribute('data-range-type')) === 1) rangeSlider.style.width = "60rem";else {
        rangeSlider.style.width = "40rem";
      }

      if (form.querySelector("datalist") !== null) {
        rangeBullet.querySelector("span").innerHTML = form.querySelector("option[value='" + rangeSlider.value + "']").getAttribute("label");
      } else {
        rangeBullet.querySelector("span").innerHTML = rangeSlider.value;
      }

      form.addEventListener("input", function () {
        if (form.querySelector("datalist") !== null) {
          rangeBullet.querySelector("span").innerHTML = form.querySelector("option[value='" + rangeSlider.value + "']").getAttribute("label");
        } else {
          rangeBullet.querySelector("span").innerHTML = rangeSlider.value;
        }

        var bulletPosition = (rangeSlider.value - rangeSlider.min) / (rangeSlider.max - rangeSlider.min);
        rangeBullet.style.left = bulletPosition * (parseInt(rangeSlider.style.width.substr(0, rangeSlider.style.width.length)) - 3) + "rem";

        if (parseInt(rangeSlider.getAttribute('data-range-type')) === 2) {
          color = 'linear-gradient(to right, ' + rangeBullet.style.backgroundColor + ' ' + rangeBullet.style.left + ', transparent 0%)';
          rangeSlider.style.background = color;
        }
      }, false);
      break;

    case 'multiple':
      form = form.querySelectorAll('fieldset');

      for (var g = 0; g < form.length; g++) {
        animButtonOnSelect(form[g], true);
      }

      break;

    default:
      break;
  }
}

function animButtonOnHover(article) {
  var multiple = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var type, form, containers, input, label, text, checked;

  if (multiple) {
    type = article.getAttribute("data-subquestion-type");
    form = article;
  } else {
    type = article.getAttribute("data-question-type");
    form = article.querySelector('.container-form');
  }

  switch (type) {
    case 'qcm-radio':
      containers = form.querySelectorAll('.container-answer');

      var _loop2 = function _loop2(e) {
        containers[e].addEventListener('mouseover', function () {
          input = containers[e].querySelector('input');

          if (!input.checked) {
            label = containers[e].querySelector('label');
            checked = form.querySelector('input:checked');
            label.style.background = label.getAttribute("data-background");
            if (checked) label.style.color = getColor('text');
          }
        });
        containers[e].addEventListener('mouseout', function () {
          input = containers[e].querySelector('input');

          if (!input.checked) {
            label = containers[e].querySelector('label');
            checked = form.querySelector('input:checked');
            label.style.background = getColor("default-button");
            if (checked) label.style.color = getColor('deactiv-button');
          }
        });
      };

      for (var e = 0; e < containers.length; e++) {
        _loop2(e);
      }

      break;

    case 'qcm-checkbox':
      containers = form.querySelectorAll('.container-answer');

      var _loop3 = function _loop3(e) {
        containers[e].addEventListener('mouseover', function () {
          input = containers[e].querySelector('input');

          if (!input.checked) {
            label = containers[e].querySelector('label');
            label.style.background = label.getAttribute("data-background");
          }
        });
        containers[e].addEventListener('mouseout', function () {
          if (!input.checked) {
            label.style.background = getColor("default-button");
          }
        });
      };

      for (var e = 0; e < containers.length; e++) {
        _loop3(e);
      }

      break;

    case 'multiple':
      form = form.querySelectorAll('fieldset');

      for (var g = 0; g < form.length; g++) {
        animButtonOnHover(form[g], true);
      }

      break;

    default:
      break;
  }
}

function lastAdvice() {
  var container = document.getElementById('advice-conclusion');
  var containerSlider = container.querySelector('.container-slider');
  var boxAdvices = container.querySelectorAll('div.box');
  var button = container.querySelector('.lastAdvice');
  var buttonNext = container.querySelector('.nextAdvice');
  var buttonSwitchPage = container.querySelector('.nextSlide');
  var thematique, index;
  button.addEventListener('click', function () {
    for (var z = 0; z < boxAdvices.length; z++) {
      if (!boxAdvices[z].classList.contains('hidden')) {
        boxAdvices[z].classList.add('hidden');

        if (z === 0) {
          index = boxAdvices.length - 1;
        } else {
          index = z - 1;
        }

        boxAdvices[index].classList.remove('hidden');
        thematique = boxAdvices[index].getAttribute('data-idt');
        containerSlider.style.backgroundColor = thematiques[thematique].backColor;
        button.style.backgroundColor = thematiques[thematique].buttonColor;
        buttonNext.style.backgroundColor = thematiques[thematique].buttonColor;
        buttonSwitchPage.style.backgroundColor = thematiques[thematique].buttonColor;
        break;
      }
    }
  });
}

function nextAdvice() {
  var container = document.getElementById('advice-conclusion');
  var containerSlider = container.querySelector('.container-slider');
  var boxAdvices = container.querySelectorAll('div.box');
  var button = container.querySelector('.nextAdvice');
  var buttonLast = container.querySelector('.lastAdvice');
  var buttonSwitchPage = container.querySelector('.nextSlide');
  var thematique, index;
  button.addEventListener('click', function () {
    for (var z = 0; z < boxAdvices.length; z++) {
      if (!boxAdvices[z].classList.contains('hidden')) {
        boxAdvices[z].classList.add('hidden');

        if (z + 1 === boxAdvices.length) {
          index = 0;
        } else {
          index = z + 1;
        }

        boxAdvices[index].classList.remove('hidden');
        thematique = boxAdvices[index].getAttribute('data-idt');
        containerSlider.style.backgroundColor = thematiques[thematique].backColor;
        button.style.backgroundColor = thematiques[thematique].buttonColor;
        buttonLast.style.backgroundColor = thematiques[thematique].buttonColor;
        buttonSwitchPage.style.backgroundColor = thematiques[thematique].buttonColor;
        break;
      }
    }
  });
}

function checkResponse(currentInput) {
  currentInput.addEventListener("change", function () {
    checkResponseByType(currentInput);
  });
}

function checkResponseByType(currentInput) {
  var button = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var nextButton, type, inputSelected;

  if (button === null) {
    nextButton = currentInput.querySelector('button.nextSlide');
  } else {
    nextButton = button;
  }

  type = currentInput.hasAttribute("data-question-type") ? currentInput.getAttribute("data-question-type") : currentInput.getAttribute("data-subquestion-type");

  switch (type) {
    case 'qcm-radio':
      var qcmRadioInputs = currentInput.querySelectorAll('.container-answer');

      for (var b = 0; b < qcmRadioInputs.length; b++) {
        if (qcmRadioInputs[b].querySelector('input').checked === true) {
          if (currentInput.hasAttribute('data-question-type') === true) {
            nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
          }

          currentInput.setAttribute('data-check-response', 1);
          break;
        } else {
          if (b === qcmRadioInputs.length - 1) {
            currentInput.setAttribute('data-check-response', 0);
          }
        }
      }

      break;

    case 'qcm-checkbox':
      var qcmCheckBoxInputs = currentInput.querySelectorAll('.container-answer');

      for (var _b = 0; _b < qcmCheckBoxInputs.length; _b++) {
        if (qcmCheckBoxInputs[_b].querySelector('input').checked === true) {
          if (currentInput.hasAttribute('data-question-type') === true) {
            nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
          }

          currentInput.setAttribute('data-check-response', 1);
          break;
        } else {
          if (_b === qcmCheckBoxInputs.length - 1) {
            currentInput.setAttribute('data-check-response', 0);
          }
        }
      }

      break;

    case 'range':
      currentInput.setAttribute('data-check-response', 1);

      if (currentInput.hasAttribute('data-question-type') === true) {
        nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
      }

      currentInput.setAttribute('data-check-response', 1);
      break;

    case 'field':
      var fieldInputs = currentInput.querySelectorAll('.container-answer');
      inputSelected = fieldInputs[0].querySelector('input');
      if (inputSelected === null) inputSelected = fieldInputs[0].querySelector('textarea');

      if (inputSelected.value !== "") {
        if (currentInput.hasAttribute('data-question-type') === true) {
          nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
        }

        currentInput.setAttribute('data-check-response', 1);
        changeBorderField(currentInput);
        break;
      } else {
        currentInput.setAttribute('data-check-response', 0);
      }

      break;

    case 'select':
      var selectInputs = currentInput.querySelectorAll('.container-answer');
      inputSelected = selectInputs[0].querySelector('select');

      if (inputSelected.value !== "") {
        if (currentInput.hasAttribute('data-question-type') === true) {
          nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
        }

        currentInput.setAttribute('data-check-response', 1);
        changeBorderField(currentInput);
        break;
      } else {
        currentInput.setAttribute('data-check-response', 0);
      }

      break;

    case 'multiple':
      var multipleInputs = currentInput.querySelectorAll('[data-subquestion-type]');
      var response = 0;

      for (var c = 0; c < multipleInputs.length; c++) {
        checkResponseByType(multipleInputs[c], nextButton);
        response = multipleInputs[c].getAttribute('data-check-response') == 1 ? response + 1 : response;
      }

      if (response === multipleInputs.length) {
        currentInput.setAttribute('data-check-response', 1);

        if (nextButton.hasAttribute('data-background')) {
          nextButton.style.backgroundColor = nextButton.getAttribute('data-background');
        }
      } else {
        currentInput.setAttribute('data-check-response', 0);
      }

      break;

    default:
      console.error('Invalide quizz type ' + currentInput.getAttribute("data-question-type"));
  }
}

function changeBorderField(currentInput) {
  if (currentInput.getAttribute('data-check-response') == 0) {
    if (currentInput.querySelector("input")) {
      currentInput.querySelector("input").style.border = ".2rem solid red";
    } else if (currentInput.querySelector("textarea")) {
      currentInput.querySelector("textarea").style.border = ".2rem solid red";
    } else if (currentInput.querySelector("select")) {
      currentInput.querySelector(".select-box").style.border = ".2rem solid red";
    }
  } else {
    if (currentInput.querySelector("input")) {
      var color = currentInput.querySelector("input").getAttribute("data-color-border");
      currentInput.querySelector("input").style.border = ".2rem solid " + color;
    } else if (currentInput.querySelector("textarea")) {
      var _color = currentInput.querySelector("textarea").getAttribute("data-color-border");

      currentInput.querySelector("textarea").style.border = ".2rem solid " + _color;
    } else if (currentInput.querySelector("select")) {
      var _color2 = currentInput.querySelector("select").getAttribute("data-color-border");

      currentInput.querySelector(".select-box").style.border = ".2rem solid " + _color2;
    }
  }
}

function getResultQuestion(currentInput) {
  var button = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var question = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var nextButton, inputName, type, container;

  if (button === null) {
    nextButton = currentInput.querySelector('button.nextSlide');
  } else {
    nextButton = button;
  }

  type = currentInput.hasAttribute("data-question-type") ? currentInput.getAttribute("data-question-type") : currentInput.getAttribute("data-subquestion-type");

  switch (type) {
    case 'qcm-radio':
      var qcmRadioInputs = currentInput.querySelectorAll('.container-answer');
      nextButton.addEventListener("click", function () {
        for (var b = 0; b < qcmRadioInputs.length; b++) {
          if (qcmRadioInputs[b].querySelector('input').checked === true) {
            inputName = qcmRadioInputs[b].querySelector('input').name;
            ANSWERS[inputName] = {};
            container = currentInput;

            while (!container.hasAttribute("data-question-type")) {
              container = container.parentElement;
            }

            if (container.querySelector('.container-query') !== null) {
              ANSWERS[inputName].question = container.querySelector('.container-query').innerHTML;
            }

            if (currentInput.hasAttribute('data-subquestion-type')) {
              ANSWERS[inputName].question = question;
              ANSWERS[inputName].subquestion = currentInput.querySelector('legend').innerHTML;
            }

            ANSWERS[inputName].value = qcmRadioInputs[b].querySelector('input').value;
            ANSWERS[inputName].answer = qcmRadioInputs[b].querySelector('label').innerHTML;
            ANSWERS[inputName].answer = ANSWERS[inputName].answer.replace(/<.+>/gi, '');
            ANSWERS[inputName].advice = qcmRadioInputs[b].querySelector('input').getAttribute('data-advice');
            ANSWERS[inputName].thematique = qcmRadioInputs[b].getAttribute('data-idT');
            break;
          }
        }
      });
      break;

    case 'qcm-checkbox':
      var qcmCheckBoxInputs = currentInput.querySelectorAll('.container-answer');
      nextButton.addEventListener("click", function () {
        for (var b = 0; b < qcmCheckBoxInputs.length; b++) {
          if (qcmCheckBoxInputs[b].querySelector('input').checked === true) {
            inputName = qcmCheckBoxInputs[b].querySelector('input').name;
            ANSWERS[inputName] = {};
            container = currentInput;

            while (!container.hasAttribute("data-question-type")) {
              container = container.parentElement;
            }

            if (container.querySelector('.container-query') !== null) {
              ANSWERS[inputName].question = container.querySelector('.container-query').innerHTML;
            }

            if (currentInput.hasAttribute('data-subquestion-type')) {
              ANSWERS[inputName].question = question;
              ANSWERS[inputName].subquestion = currentInput.querySelector('legend').innerHTML;
            }

            ANSWERS[inputName].value = qcmCheckBoxInputs[b].querySelector('input').value;
            ANSWERS[inputName].answer = qcmCheckBoxInputs[b].querySelector('label').innerHTML;
            ANSWERS[inputName].answer = ANSWERS[inputName].answer.replace(/<.+>/gi, '');
            ANSWERS[inputName].advice = qcmCheckBoxInputs[b].querySelector('input').getAttribute('data-advice');
            ANSWERS[inputName].thematique = qcmCheckBoxInputs[b].getAttribute('data-idT');
          }
        }
      });
      break;

    case 'range':
      var rangeInputs = currentInput.querySelectorAll('.container-answer');
      nextButton.addEventListener("click", function () {
        inputName = rangeInputs[0].querySelector('input').name;
        ANSWERS[inputName] = {};
        container = currentInput;

        while (!container.hasAttribute("data-question-type")) {
          container = container.parentElement;
        }

        if (container.querySelector('.container-query') !== null) {
          ANSWERS[inputName].question = container.querySelector('.container-query').innerHTML;
        }

        if (currentInput.hasAttribute('data-subquestion-type')) {
          ANSWERS[inputName].question = question;
          ANSWERS[inputName].subquestion = currentInput.querySelector('legend').innerHTML;
        }

        if (rangeInputs[0].querySelector('datalist')) {
          ANSWERS[inputName].answer = rangeInputs[0].querySelector('option[value="' + rangeInputs[0].querySelector('input').value + '"]').getAttribute('label');
          ANSWERS[inputName].advice = rangeInputs[0].querySelector('option[value="' + rangeInputs[0].querySelector('input').value + '"]').getAttribute('data-advice');
          ANSWERS[inputName].advice += rangeInputs[0].querySelector('option[value="' + rangeInputs[0].querySelector('input').value + '"]').getAttribute('label').toLowerCase() + ".";
        } else {
          ANSWERS[inputName].answer = rangeInputs[0].querySelector('input').value;
          ANSWERS[inputName].advice = rangeInputs[0].querySelector('input').getAttribute('data-advice');
          ANSWERS[inputName].advice += rangeInputs[0].querySelector('input').value + " €.";
        }

        ANSWERS[inputName].thematique = rangeInputs[0].getAttribute('data-idT');
      });
      break;

    case 'field':
      var fieldInputs = currentInput.querySelectorAll('.container-answer');
      nextButton.addEventListener("click", function () {
        var inputSelected = fieldInputs[0].querySelector('input');
        if (inputSelected === null) inputSelected = fieldInputs[0].querySelector('textarea');
        inputName = inputSelected.name;
        ANSWERS[inputName] = {};
        container = currentInput;

        while (!container.hasAttribute("data-question-type")) {
          container = container.parentElement;
        }

        if (container.querySelector('.container-query') !== null) {
          ANSWERS[inputName].question = container.querySelector('.container-query').innerHTML;
        }

        if (currentInput.hasAttribute('data-subquestion-type')) {
          ANSWERS[inputName].question = question;
          ANSWERS[inputName].subquestion = currentInput.querySelector('legend').innerHTML;
        }

        ANSWERS[inputName].answer = inputSelected.value;
        ANSWERS[inputName].thematique = fieldInputs[0].getAttribute('data-idT');
      });
      break;

    case 'select':
      var selectInputs = currentInput.querySelectorAll('.container-answer');
      nextButton.addEventListener("click", function () {
        inputName = selectInputs[0].querySelector('select').name;
        ANSWERS[inputName] = {};
        container = currentInput;

        while (!container.hasAttribute("data-question-type")) {
          container = container.parentElement;
        }

        if (container.querySelector('.container-query') !== null) {
          ANSWERS[inputName].question = container.querySelector('.container-query').innerHTML;
        }

        if (currentInput.hasAttribute('data-subquestion-type')) {
          ANSWERS[inputName].question = question;
          ANSWERS[inputName].subquestion = currentInput.querySelector('legend').innerHTML;
        }

        ANSWERS[inputName].answer = selectInputs[0].querySelector('select').value;
        ANSWERS[inputName].thematique = selectInputs[0].getAttribute('data-idT');
      });
      break;

    case 'multiple':
      var multipleInputs = currentInput.querySelectorAll('[data-subquestion-type]');

      if (currentInput.querySelector('.container-query')) {
        question = currentInput.querySelector('.container-query').innerHTML;
      } else {
        question = currentInput.classList.value;
      }

      for (var c = 0; c < multipleInputs.length; c++) {
        getResultQuestion(multipleInputs[c], nextButton, question);
      }

      break;

    default:
      console.error('Invalide quizz type ' + currentInput.getAttribute("data-question-type"));
  }
}

function computeGoodPercent() {
  var countPoint = 0;

  for (var i in ANSWERS) {
    if (ANSWERS[i].hasOwnProperty('value')) {
      if (Number.isInteger(parseInt(ANSWERS[i].value))) {
        countPoint += parseInt(ANSWERS[i].value);
      }
    }
  }

  var inputs,
      containerSubquestion,
      maxValue = 0,
      totalPoint = 0;
  var containerForm = document.getElementsByClassName('container-form');

  for (var j = 0; j < containerForm.length; j++) {
    containerSubquestion = containerForm[j].querySelectorAll('fieldset[data-subquestion-type]');

    if (containerSubquestion.length != 0) {
      for (var k = 0; k < containerSubquestion.length; k++) {
        inputs = containerSubquestion[k].querySelectorAll('input');
        maxValue = 0;

        for (var l = 0; l < inputs.length; l++) {
          var isValidValue = true;

          if (inputs[l].hasAttribute('data-range-type')) {
            isValidValue = false;
          }

          if (maxValue < parseInt(inputs[l].value) && isValidValue) {
            maxValue = parseInt(inputs[l].value);
          }
        }

        totalPoint += maxValue;
      }
    } else {
      inputs = containerForm[j].querySelectorAll('input');
      maxValue = 0;

      for (var _l = 0; _l < inputs.length; _l++) {
        var _isValidValue = true;

        if (inputs[_l].hasAttribute('data-range-type')) {
          _isValidValue = false;
        }

        if (maxValue < parseInt(inputs[_l].value) && _isValidValue) {
          maxValue = parseInt(inputs[_l].value);
        }
      }

      totalPoint += maxValue;
    }
  }

  percent = countPoint / totalPoint * 100;
}

function displayAdvice() {
  var button = document.getElementById('advice-conclusion').querySelector('.nextSlide');
  var container = document.getElementById('container-slider');
  var containerContent = '';
  var currentThematique,
      lastThematique = undefined;

  for (var i in ANSWERS) {
    currentThematique = ANSWERS[i].thematique;

    if (currentThematique != -1) {
      if (lastThematique != currentThematique) {
        if (lastThematique !== undefined) {
          containerContent += '</ul>' + '</div>';
          containerContent += '<div class="box hidden" data-idT="' + currentThematique + '">' + '<h3 class="title">' + thematiques[currentThematique].title + '</h3>' + '<ul class="content">';
        } else {
          container.style.backgroundColor = thematiques[currentThematique].backColor;
          button.style.backgroundColor = thematiques[currentThematique].buttonColor;
          containerContent += '<div class="box" data-idT="' + currentThematique + '">' + '<h3 class="title">' + thematiques[currentThematique].title + '</h3>' + '<ul class="content">';
        }
      } // if (lastThematique == currentThematique) {


      if (ANSWERS[i].advice != "" && ANSWERS[i].advice != undefined) {
        containerContent += '<li>' + ANSWERS[i].advice + '</li>';
      } // }


      lastThematique = currentThematique;
    }
  }

  containerContent += '</ul>' + '</div>';
  container.insertAdjacentHTML('afterbegin', containerContent);
}

var uniqId = (new Date().getTime() + Math.floor(Math.random() * 10000 + 1)).toString(16);

function submitFormDatas() {
  for (var i in ANSWERS) {
    ANSWERS[i].question = ANSWERS[i].question.replace(/(&nbsp;){1}/gi, ' ');
    if (ANSWERS[i].advice) ANSWERS[i].advice = ANSWERS[i].advice.replace(/(&nbsp;){1}/gi, ' ');
  }

  percent = Math.round(percent);
  var url = config.url.submitFormDatas;
  var xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (this.readyState === 4) {
      if (this.status === 200) {
        console.log('Successfull First Request');
      } else {
        reject(xhr);
      }
    }
  };

  xhr.open('POST', url, true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr.send(JSON.stringify({
    id: uniqId,
    score: percent,
    datas: ANSWERS
  }));
}

function submitContactDatas() {
  var url = config.url.submitContactDatas;
  var xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        console.log('Successfull Second Request');
      } else {
        reject(xhr);
      }
    }
  };

  xhr.open('POST', url, true);
  xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
  xhr.send(JSON.stringify({
    id: uniqId,
    datas: ANSWERS
  }));
} //----------------------carousel----------------------------------


function load_carousel() {
  // Variables to target our base class,  get carousel items, count how many carousel items there are, set the slide to 0 (which is the number that tells us the frame we're on), and set motion to true which disables interactivity.
  var itemClassName = "carousel__photo";
  var items = document.getElementsByClassName(itemClassName),
      totalItems = items.length,
      slide = 0,
      moving = true;

  function setInitialClasses() {
    // Target the last, initial, and next items and give them the relevant class.
    // This assumes there are three or more items.
    // items[totalItems - 1].classList.add("prev");
    items[0].classList.add("active");
    items[1].classList.add("next");
  } // Set click events to navigation buttons


  function setEventListeners() {
    var buttonsNext = document.getElementsByClassName('carousel__button--next');

    for (var i = 0; i < buttonsNext.length; i++) {
      buttonsNext[i].addEventListener('click', moveNext);
    }

    var buttonsPrev = document.getElementsByClassName('carousel__button--prev');

    for (var _i = 0; _i < buttonsPrev.length; _i++) {
      buttonsPrev[_i].addEventListener('click', movePrev);
    }
  }

  function disableInteraction() {
    // Disable interaction by setting 'moving' to true for the same duration as our transition (0.5s = 500ms)
    moving = true;
    setTimeout(function () {
      moving = false;
    }, 500);
  }

  function moveCarouselTo(slide, sens) {
    if (!moving) {
      // temporarily disable interactivity
      disableInteraction(); // Preemptively set variables for the current next and previous slide, as well as the potential next or previous slide.

      var newPrevious = slide - 1,
          newNext = slide + 1,
          oldPrevious = slide - 2,
          oldNext = slide + 2; // Test if carousel has more than three items

      if (totalItems - 1 > 3) {
        // Checks if the new potential slide is out of bounds and sets slide numbers
        if (newPrevious <= 0) {
          oldPrevious = totalItems - 1;
        } else if (newNext >= totalItems - 1) {
          oldNext = 0;
        } // Check if current slide is at the beginning or end and sets slide numbers


        if (slide === 0) {
          newPrevious = totalItems - 1;
          oldPrevious = totalItems - 2;
          oldNext = slide + 1;
        } else if (slide === totalItems - 1) {
          newPrevious = slide - 1;
          newNext = 0;
          oldNext = 1;
        }

        if (sens == "next") {
          // // Based on the current slide, reset to default classes.
          // items[oldPrevious].classList.remove("prev");
          // items[oldNext].classList.remove("next");
          // // Add the new classes
          // items[newPrevious].classList.remove("active");
          // items[newPrevious].classList.add("prev");
          // items[slide].classList.remove("next");
          // items[slide].classList.add("active");
          // items[newNext].classList.add("next");
          if (slide - 2 >= 0) {
            items[slide - 2].classList.remove("prev");
          }

          items[slide - 1].classList.remove("active");
          items[slide - 1].classList.add("prev");
          items[slide].classList.remove("next");
          items[slide].classList.add("active");

          if (slide + 1 !== items.length) {
            items[slide + 1].classList.add("next");
          }
        } else if (sens == "prev") {
          items[slide - 1].classList.add("prev");
          items[slide].classList.remove("prev");
          items[slide].classList.add("active");
          items[slide + 1].classList.remove("active");
          items[slide + 1].classList.add("next");

          if (slide + 2 !== items.length - 2) {
            items[slide + 2].classList.remove("next");
          } // items[oldPrevious].className = itemClassName;
          // items[oldNext].className = itemClassName;
          // items[newPrevious].className = itemClassName + " prev";
          // items[slide].className = itemClassName + " active";
          // items[newNext].className = itemClassName + " next";

        }
      }
    }
  }

  function moveNext() {
    // Next navigation handler
    // Previous navigation handler
    if (!moving) {
      // Check if moving
      if (items[slide].getAttribute("id") != "home-box") {
        var article = items[slide].querySelector("article");

        if (article.getAttribute("data-check-response") != null) {
          if (article.getAttribute("data-check-response") == 1) {
            if (article.querySelector("input#cbxRgpd")) {
              if (article.querySelector("input#cbxRgpd").checked == true) {
                slide++;
                moveCarouselTo(slide, "next");
              }
            } else {
              slide++;
              moveCarouselTo(slide, "next");
            }
          }
        } else {
          slide++;
          moveCarouselTo(slide, "next");
        }
      } else {
        slide++;
        moveCarouselTo(slide, "next");
      }
    }
  }

  function movePrev() {
    // Check if moving
    // To initialise the carousel we'll want to update the DOM with our own classes
    if (!moving) {
      slide--; // Move carousel to updated slide

      moveCarouselTo(slide, "prev");
    }
  }

  function initCarousel() {
    setInitialClasses();
    setEventListeners();
    moving = false;
  }

  initCarousel();
}